import React, { useEffect, useRef, useState } from "react";

import { api, apiUser } from "../../../../services/base.service";
import { cleanStorage, setItem } from "../../../../services/storage.service";
import { useCookies } from "react-cookie";

import { Card, Logo, Typography, Input } from "../../../../components";
import { Button } from "../../../../components";
import * as Yup from "yup";
import * as S from "./FormLoginStyled";
import { useAuth } from "../../../../context/auth.context";
import ReactModal from "react-modal";
const TYPE_USER = { RESPOSIBLE: 5, STUDENT: 6, TEACHER: 4, ADM_MASTER: 5 };

const customStyles = {
  content: {
    // width: "500px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const FormLogin = ({ props }) => {
  const formRef = useRef(null);
  const [errorAuth, setErrorAuth] = useState([]);

  const [main_domain, setMain_domain] = useState(
    process.env.REACT_APP_MAIN_DOMAIN
  );
  const [main_url, setMain_url] = useState(process.env.REACT_APP_MAIN_URL);
  const [tutor_url, setTutor_url] = useState(process.env.REACT_APP_TUTOR_URL);
  const [hub_url, setHub_url] = useState(process.env.REACT_APP_HUB_URL);
  // const main_domain = process.env.REACT_APP_MAIN_DOMAIN;
  // const main_url = process.env.REACT_APP_MAIN_URL;
  // const tutor_url = process.env.REACT_APP_TUTOR_URL;
  // const hub_url = process.env.REACT_APP_HUB_URL;
  const { setStatusLoginActive, setUserActive } = useAuth();
  const main_domain_localhost = "localhost";
  const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);
  const [isUserLogged, setIsUserLogged] = useState(false);
  //   const [statusLogin, setStatusLogin] = useState(null);
  const [loader, setLoader] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  console.log(props);
  useEffect(() => {
    if (window.location.hostname === "localhost") {
      setMain_domain("localhost");
      setMain_url("http://localhost:3000");
      setTutor_url("http://localhost:4200");
      setHub_url("http://localhost:4200");
    }
    if (window.location.hostname === "h.conectedu.com") {
      setMain_url("https://h.conectedu.com");
      setTutor_url("https://h.tutor.conectedu.com");
      setHub_url("https://h.hub.conectedu.com");
    }
    // console.log(window.location.hostname); //yields: "/js" (where snippets run)
    // console.log(window.location.href);
    // console.log(cookies, "cookies");
    if (props.match.params.logout === "true") {
      cleanStorage();
      removeCookie("token", {
        path: "/",
        domain: main_domain,
      });
      removeCookie("name", {
        path: "/",
        domain: main_domain,
      });
      removeCookie("id", {
        path: "/",
        domain: main_domain,
      });
      window.location = main_url;
    }
  }, []);

  async function handleSubmit(data) {
    setErrorAuth([]);
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string().required("Esse campo é obrigatório"),
        password: Yup.string().required("Esse campo é obrigatório"),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      console.log(data);
      postAuth(data);
    } catch (err) {
      console.log(err);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  const postAuth = (data) => {
    setLoader(true);
    console.log(cookies);
    api
      .post("identidade/autenticar", {
        login: data.email,
        senha: data.password,
      })
      .then((res) => {
        console.log(res.data.data.usuarioToken.id);
        const config = {
          headers: { Authorization: `Bearer ${res.data.data.accessToken}` },
        };
        apiUser
          .get(`Usuario/${res.data.data.usuarioToken.id}`, config)
          .then((result) => {
            if (res.data.data.senhaPadrao && result.data.tipoUsuarioTv === 3) {
              setUserActive({
                login: data.email,
                id: res.data.data.usuarioToken.id,
              });
              setStatusLoginActive(1);
              return;
            }
            setCookie("token", res.data.data.accessToken, {
              path: "/",
              domain: main_domain,
            });
            setCookie("name", result.data.nome, {
              path: "/",
              domain: main_domain,
            });
            setCookie("id", res.data.data.usuarioToken.id, {
              path: "/",
              domain: main_domain,
            });

            setCookie("token", res.data.data.accessToken, {
              path: "/",
              domain: main_domain_localhost,
            });
            setCookie("name", result.data.nome, {
              path: "/",
              domain: main_domain_localhost,
            });
            setCookie("id", res.data.data.usuarioToken.id, {
              path: "/",
              domain: main_domain_localhost,
            });
            setItem("token", res.data.data.accessToken);
            setItem("name", result.data.nome);
            setItem("email", res.data.data.usuarioToken.email);
            setItem("tipoUsuario", result.data.tipoUsuario);

            directUser(result.data.tipoUsuario);
            setLoader(false);
          });
      })
      .catch((error) => {
        setErrorAuth(error.response.data.errors);
        setLoader(false);
      });
  };

  const directUser = (typeUser) => {
    switch (typeUser) {
      case 2:
      case 8:
        window.location = hub_url;
        break;
      case 3:
        window.location = hub_url;
        break;
      case TYPE_USER.TEACHER:
        window.location = tutor_url;
        break;
      case 5:
        setErrorAuth(["Usuário ou Senha incorretos"]);
        break;
      case 6:
        window.location = tutor_url;
        break;
      case 7:
        window.location = hub_url;
        break;
      default:
        return;
    }
  };

  const logout = () => {
    cleanStorage();
    // window.location.reload();
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Card title="Fazer login">
      <S.FormContainer ref={formRef} onSubmit={handleSubmit}>
        <Input label="E-mail/N• de Matrícula" name="email" type="text" />
        <S.InputPassword>
          <Input label="Senha" name="password" type="password" />
        </S.InputPassword>
        {errorAuth.length > 0 && (
          <S.MensageError>- {errorAuth[0]}</S.MensageError>
        )}
        <Button loader={loader} onClick={logout}>
          Login
        </Button>
        <S.TextPassword variant="a" href="/forgot-password">
          Esqueceu sua senha?
        </S.TextPassword>
        <S.FooterAuth>
          {/* <S.TextNotAcess variant="a" href="/esqueci-minha-senha">
            Não consegue realizar o acesso ?
          </S.TextNotAcess>
          • */}
          <S.TextTermsOfUse target="_blank" variant="a" href="/termos-de-uso">
            Termo de uso
          </S.TextTermsOfUse>
        </S.FooterAuth>
        {/* <S.ManualDoAluno onClick={openModal}>
          Clique aqui para ter acesso ao manual
        </S.ManualDoAluno> */}
      </S.FormContainer>
      <div>
        <ReactModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
          <S.ButtonCloseModal onClick={closeModal}>Fechar</S.ButtonCloseModal>
          <S.ContainerManuais>
            <Logo marginBottom={20} marginTop={30} size={100} />
            <S.ButtonManual>
              <S.ManualDoAluno
                target="_blank"
                href="https://condominioappstorage.blob.core.windows.net/macae/manuais/Tutorial%20Aluno%20Educa%20Macae.pdf"
                download
              >
                Clique aqui para acessar o manual do aluno.
              </S.ManualDoAluno>
            </S.ButtonManual>
            <S.ButtonManual>
              <S.ManualDoAluno
                target="_blank"
                href="https://condominioappstorage.blob.core.windows.net/macae/manuais/Tutorial%20professor%20Macae.pdf"
              >
                Clique aqui para acessar o manual do Professor.
              </S.ManualDoAluno>
            </S.ButtonManual>
            <S.ButtonManual>
              <S.ManualDoAluno
                target="_blank"
                href="https://condominioappstorage.blob.core.windows.net/macae/manuais/Tutorial_Gesta%CC%83o_%20Macae.pdf"
              >
                Clique aqui para acessar o manual do Gestor.
              </S.ManualDoAluno>
            </S.ButtonManual>
            {/* <S.ButtonManual>
              <S.ManualDoAluno
                target="_blank"
                href="https://condominioappstorage.blob.core.windows.net/barradopirai/Administrador%20-%20M%C3%B3dulo%201.pdf"
              >
                Administrador - Ações administrativas.
              </S.ManualDoAluno>
            </S.ButtonManual>
            <S.ButtonManual>
              <S.ManualDoAluno
                target="_blank"
                href="https://condominioappstorage.blob.core.windows.net/barradopirai/Administrador%20-%20M%C3%B3dulo%202.pdf"
              >
                Administrador - Construindo diário de classe.
              </S.ManualDoAluno>
            </S.ButtonManual> */}
            {/* <Button></Button> */}
            {/* <div>I am a modal</div> */}
          </S.ContainerManuais>
        </ReactModal>
      </div>
    </Card>
  );
};

export default FormLogin;
