import React, { useEffect, useState } from "react";
import ForgotPasswordScreen from "./components/ForceResetPassword/ForceResetPassword";
import FormLogin from "./components/FormLogin/FormLogin";
import * as S from "./AuthStyled";
import { useAuth } from "../../context/auth.context";

const TYPE_USER = { RESPOSIBLE: 5, STUDENT: 6, TEACHER: 4, ADM_MASTER: 5 };

const AuthScreen = (props) => {
  const { statusLogin, user } = useAuth();
  useEffect(() => {
    console.log(statusLogin);
  });
  return (
    <S.Container>
      <S.ContainerImgLeft>
        {/* <S.ImgLeft src="https://condominioappstorage.blob.core.windows.net/degase/brasao-degase-300.png" /> */}
      </S.ContainerImgLeft>
      {/* <S.ContainerLeft> */}
      <S.ContainerAuth>
        {statusLogin === 0 ? (
          <S.BoxLogin>
            <FormLogin props={props}></FormLogin>
            {/* <S.ImgLogo
              style={{ width: "250px", marginTop: 15 }}
              src="https://condominioappstorage.blob.core.windows.net/abcdeduca/logo-header.svg"
            /> */}
          </S.BoxLogin>
        ) : (
          <ForgotPasswordScreen
            login={user.login}
            id={user.id}
          ></ForgotPasswordScreen>
        )}
        {/* <ForgotPasswordScreen id={idUserLogged}></ForgotPasswordScreen> */}
        {/* {idUserLogged ? (
            <ForgotPasswordScreen id={idUserLogged}></ForgotPasswordScreen>
          ) : (
            "Não logado"
          )} */}
      </S.ContainerAuth>
      {/* </S.ContainerLeft> */}
    </S.Container>
  );
};

export default AuthScreen;
